@import "../../index";

$headline-font-weight: $font-weight-bolder;
$headline-font-size: 18px;
$headline-line-height: 24px;

$headline-font-weight-sm: 400;
$headline-font-size-sm: 16px;
$headline-line-height-sm: 22px;

.footer {
  background-color: #f2f3f5;
  padding-top: 4%;
  overflow: hidden;

  :global(.headline) {
    font-weight: #{$headline-font-weight};
    font-size: #{$headline-font-size};
    line-height: #{$headline-line-height};

    @include media-breakpoint-down(sm) {
      font-size: #{$headline-font-size-sm};
      line-height: #{$headline-line-height-sm};
    }
  }

  a {
    text-decoration: none;
    color: inherit;

    &:global(.stretched-link) {
      img {
        width: auto;
        height: auto;
      }
    }
  }


  mark {
    color: #{$danger};
    background-color: transparent;
  }

  hr {
    border-top: 2px solid color("white");
  }

  :global(.footer-links) {
    h5 {
      @include text-style(14px, 22px, $font-weight-medium);
      @include media-breakpoint-up(sm) {
        @include text-style(20px, 34px, $font-weight-bolder);
      }
    }
  }

  :global(.my-desc-footer){
    a{
      color: #e14558;
    }
  }
  :global(.footer-service) {
    @include media-breakpoint-down(sm) {
      font-size: 10px;
    }

    img {
      width: auto;
      height: auto;
    }
  }

  :global(.footer-about) {
    a {
      display: block;
    }
  }

  :global(.footer-download) {
    &-icon {
      border-radius: 5px;
      height: 40px;
      width: auto;
    }

    // display: inline-block;
    // position: relative;

    // a {
    //   &::after {
    //     width: 50%;
    //     left: 0;
    //     right: auto;
    //   }

    //   & + a {
    //     &::after {
    //       width: 50%;
    //       left: auto;
    //       right: 0;
    //     }
    //   }
    // }
  }

  :global(.footer-social) {
    &-icon {
      height: 40px;
      width: auto;
      margin-right: 0.25rem;
    }
  }

  :global(.footer-contact) {
    &-icon {
      width: 23px;
      height: auto;
      display: inline-block;
      margin-right: 1rem;
    }

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;

      li {
        padding: 0.25rem 0;
      }
    }
  }

  :global(.footer-partner) {
    &-icon {
      max-width: 100%;
      height: auto;
      width: auto;
      max-height: 80px;
    }
  }

  :global(.footer-nav) {
    background-color: #2c2c2c;
    @include text-style(16px, 24px, $font-weight-normal);

    @include media-breakpoint-down(sm) {
      @include text-style(12px, 18px, $font-weight-normal);
    }

    :global(.row) {
      min-height: 64px;
    }

    a {
      color: color("white");
    }
  }
}
