@import "../../../index";

.navigation-about {
  box-shadow: 0 1px 1px #f2f3f5;

  .navigation-wrapper {
    display: block;
    width: 100%;
    text-align: center;
    
    a {
      display: inline-block;
      font-weight: 500;
      color: #676767;
      font-size: 16px;
      text-decoration: none;
      height: 60px;
      padding: 0 1rem;
      line-height: 60px;
      white-space: nowrap;

      @include media-breakpoint-up(sm) {
        padding: 0 2rem;
      }

      &:hover,
      &.active {
        color: #{$blue};
        border-bottom: 4px solid #{$blue};
      }
    }
  }
}
