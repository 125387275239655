@import "../../index";

#navigation {
  height: 80px;
  padding: 10px 0;
  position: fixed;
  display: block;
  width: 100%;
  z-index: 101;

  @include media-breakpoint-down(sm) {
    height: 60px;
  }

  .navbar-collapse {
    @include media-breakpoint-down(sm) {
      background-color: #ffffff;
      position: fixed;
      width: 100%;
      left: 0;
      right: 0;
      top: 65px;
    }
  }

  &.white {
    background-color: #ffffff !important;
    box-shadow: 0 0 4px #d8d8d8;
  }

  .navbar {
    padding: 0;
  }

  .switch-language {
    margin: 2px 0 0 10px;

    @include media-breakpoint-down(sm) {
      display: block !important;
    }

    a {
      margin-left: 7px;
      padding-right: 7px;
      text-decoration: none;
      border-right: 1.5px solid #{$black};
      @include text-style(12px, 18px, $font-weight-normal, $gray-600);

      &:hover {
        color: #{$black};
      }

      &:last-child {
        border-right: none;
      }

      &.active {
        color: #{$black};
        font-weight: #{$font-weight-bolder};
      }
    }
  }

  .logo {
    width: 142px;
    height: auto;
    margin-top: -4px;
    @include media-breakpoint-down(sm) {
      margin-top: 0;
    }
  }

  .dropdown-icon {
    width: 11px;
    height: 11px;
    margin-left: 9px;
    margin-bottom: 7px;
    margin-top: 5px;
  }

  .dropdown-menu {
    padding: 0.25rem;
    width: 360px;
  }

  .dropdown-item {
    white-space: normal;
    word-break: break-word;
    padding: 0.5rem 1rem;

    &:hover {
      background-color: #E6F2FE;
    }

    &:focus {
      background-color: #E6F2FE;
    }

    &:active {
      background-color: #E6F2FE;
    }
  }

  button.registration {
    padding: 0 24px;
    height: 46px;
    border-radius: 22px;
    @include text-style(18px, 24px, $font-weight-medium);
  }

  .nav-item {
    white-space: nowrap;

    a {
      @include text-style(16px, 24px, $font-weight-medium, $black);
    }
  }

  .about {
    width: 100%;
    position: absolute;
    background-color: #f2f3f5;
    text-align: center;
    display: none;
    box-shadow: 0 1px 1px #dfdfdf;

    a {
      display: inline-block;
      margin: 0 5px;
      color: #676767;
      padding: 0 20px;
      line-height: 50px;
      text-decoration: none;

      &.active {
        position: relative;
        top: 1px;
        color: #{$blue};
        border-bottom: 4px solid #{$blue};
      }
    }
  }

  .menu-toggle {
    margin-top: 4px;
    width: 30px;
    height: auto;
    cursor: pointer;
  }

  .mobile-navigation {
    padding: 22px 37px;
    background: #ffffff;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    overflow-y: scroll;
    transform: translateX(-100%);
    transition: transform 0.25s ease-in;

    &.show {
      transform: translateX(0);
    }

    .navigation-close {
      position: absolute;
      top: 30px;
      right: 35px;
      width: 25px;
      height: auto;
    }

    hr {
      margin: 30px 0;
    }

    .menu {
      width: 100%;

      h6 {
        @include text-style(16px, 32px, $font-weight-medium);
      }

      a {
        width: 100%;
        display: block;
        color: #28292a;
        margin: 15px 0;
        @include text-style(16px, 32px);
      }
    }
  }
}
