@import "./bootstrap/functions";

//
//
$blue: #1567B4 !default;
$primary-blue:#0092FF;

//
// Grid
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1300px
) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1280px
) !default;

//
// Typography
$font-family-base:    "Kanit", sans-serif !default;
$font-weight-normal:  300 !default;
$font-weight-medium:  400 !default;
$font-weight-bold:    500 !default;
$font-weight-bolder:  600 !default;

//
// Body
// $body-bg:                   $white !default;
// $body-color:                $gray-900 !default;

//
// Links
$link-color:  #212529 !default;

$breadcrumb-divider: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxMCAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICA8cGF0aCBkPSJNMSAxTDguNTMzNDUgOC44NjE2MkM4LjYwNzU3IDguOTM4OTggOC42MDc1NyA5LjA2MTAyIDguNTMzNDUgOS4xMzgzOEwxIDE3IiBzdHJva2U9ImJsYWNrIiBzdHJva2Utd2lkdGg9IjIiCiAgICBzdHJva2UtbGluZWNhcD0icm91bmQiIC8+Cjwvc3ZnPg==);

$navigator-height: 80px;
$navigator-height-sm: 60px;

@import "./bootstrap/variables";
@import "./bootstrap/mixins";

@mixin text-style($font-size:$font-size-base, $line-height: null, $font-weight: null, $color: null) {
  font-size: #{$font-size};
  @if ($line-height) {
    line-height: #{$line-height};
  }
  @if ($font-weight) {
    font-weight: #{$font-weight};
  }
  @if ($color) {
    color: #{$color};
  }
}